import React from 'react';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import {
  requestRecipients,
  fetchUserDetails,
  deleteRecipient,
  showRecipient,
  showReceivingCountry,
  showSendingCountry,
  showSendingCurrency,
  showReceivingCurrency,
  showCalculation,
  showProductDetails,
  requestLimit,
  requestProductDetails,
  requestRecipientByID
} from '../actions';
import CustomFooter from '../components/footer.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, message, Table, Button, Spin, Modal, Input, Divider } from 'antd';
import '../styles/user.css'
import ErrorLayout from '../components/error-layout.js';
import config from '../env-config.json'
import ManageUrls from '../endpoints/manage_urls.js';

const { Content, Footer } = Layout;
const TAG = "SmartRecipientList";
const Search = Input.Search;
const confirm = Modal.confirm;

class RecipientList extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "RecipientList::constructor() -- props=" + JSON.stringify(props));

    const manageUrls = new ManageUrls()

    var sendingCountry = {};
    var receivingCountry = {};
    var sendingCurrency = {};
    var receivingCurrency = {};
    var product = {};
    var calculation = {};
    var beneficiarySettings = {};
    var transferReasons = [];
    var defaultTransferReason = "";
    var acceptedBeneficiaryTypes = [];
    var acceptedRecipientTypes = [];
    var paymentMethods = []
    var bwPolicy = false;

    if (props.location != null) {
      if (props.location.state != null) {
        sendingCountry = props.location.state.sendingCountry
        receivingCountry = props.location.state.receivingCountry
        sendingCurrency = props.location.state.sendingCurrency
        receivingCurrency = props.location.state.receivingCurrency
        product = props.location.state.product
        calculation = !!props.location.state.calculation.data ? props.location.state.calculation : props.calculationReducer.calculationRequest
        beneficiarySettings = props.location.state.beneficiarySettings
        transferReasons = props.location.state.transferReasons
        defaultTransferReason = props.location.state.defaultTransferReason
        acceptedBeneficiaryTypes = props.location.state.acceptedBeneficiaryTypes
        acceptedRecipientTypes = props.location.state.acceptedRecipientTypes
        paymentMethods = props.location.state.paymentMethods
        bwPolicy = props.location.state.bwPolicy
      }
    }

    Log.d(TAG, "RecipientList::constructor() -- transfer reasons=" + JSON.stringify(transferReasons));

    this.state = {
      // access: cookies.get('access') || '',
      access: this.props.authReducer.accessToken || '',
      // refresh: cookies.get('refresh') || '',
      sendingCountry : sendingCountry,
      receivingCountry : receivingCountry,
      sendingCurrency : sendingCurrency,
      receivingCurrency : receivingCurrency,
      product: product,
      calculation: calculation,
      beneficiarySettings : beneficiarySettings,
      transferReasons : transferReasons,
      defaultTransferReason : defaultTransferReason,
      acceptedBeneficiaryTypes : acceptedBeneficiaryTypes,
      acceptedRecipientTypes : acceptedRecipientTypes,
      paymentMethods : paymentMethods,
      loading : false,
      loadingTip : "",
      currentLink : (receivingCountry ? (Object.keys(receivingCountry).length > 0 ? manageUrls.getApiUrl() + "beneficiaries/?filter[beneficiary_type]=MT&filter[country]=" + receivingCountry.id + "&page=1" : manageUrls.getApiUrl() + "beneficiaries/?page=1") : manageUrls.getApiUrl() + "beneficiaries/?page=1"),
      columns : [{
        title: 'User ID',
        dataIndex: 'id',
        key: 'id',
      }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
           <Button onClick={(event) => { event.stopPropagation(); this.onDelete(record); }}
            style = {{
              'min-width' : "100px",
              'height' : '30px',
              'color': 'rgb(41, 108, 187)',
              'border-radius' : "0px",
              'border' : "1px solid rgb(41, 108, 187)"
            }}
           >Delete</Button>
          </span>
        ),
      }],
      recipient: {},
      disableAddButton: true,
      bwPolicy: bwPolicy
    }
  }

  componentWillMount() {
    Log.d(TAG, "componentWillMount() -- user=" + JSON.stringify(this.props.userReducer.user))
    Log.d(TAG, "componentWillMount() -- country=" + JSON.stringify(this.state.sendingCountry))
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.accessToken !== '') {
      Log.d(TAG, "componentWillMount() -- request a user");
      // Log.d(TAG, "componentWillMount() -- access=" + this.props.cookies.cookies.access);
      Log.d(TAG, "componentWillMount() -- access=" + this.props.authReducer.accessToken);
      // Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.cookies.cookies.access));
      Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.authReducer.accessToken));
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, null);
      this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, null);
      this.props.showRecipient({}, false);
      this.props.showReceivingCountry(this.state.receivingCountry);
      this.props.showSendingCountry(this.state.sendingCountry);
      this.props.showSendingCurrency(this.state.sendingCurrency);
      this.props.showReceivingCurrency(this.state.receivingCurrency);
      this.props.showCalculation(this.state.calculation);
      this.props.showProductDetails(this.state.product);
      this.requestProductDetails();
    }
  }
  requestRecipients(url) {
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      // Log.d(TAG, "requestRecipients() -- access=" + cookies.get('access'));
      Log.d(TAG, "requestRecipients() -- access=" + this.props.authReducer.accessToken);
      this.setState({
        loading : true,
        loadingTip : "Fetching beneficiaries .."
      })

      // this.props.requestRecipients(url, cookies.get('access'));
      this.props.requestRecipients(url, this.props.authReducer.accessToken);
    }
  }

  requestProductDetails() {
    Log.d(TAG, "requestProductDetails() -- product=" + JSON.stringify(this.props.productServiceReducer.product));
    Log.d(TAG, "requestProductDetails() -- sending country=" + JSON.stringify(this.props.countryReducer.sendingCountry));
    Log.d(TAG, "requestProductDetails() -- receiving country=" + JSON.stringify(this.props.countryReducer.receivingCountry));
    if (Object.keys(this.props.productServiceReducer.product).length > 0
      && Object.keys(this.props.countryReducer.sendingCountry).length > 0
      && Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
        var sendingCountryAlpha = this.props.countryReducer.sendingCountry.attributes.alpha_2_code;
        var receivingCountryAlpha = this.props.countryReducer.receivingCountry.attributes.alpha_2_code;
        var token = null;
        // if (cookies.get('access')) {
        if (!!this.props.authReducer.accessToken) {
          // token = cookies.get('access');
          token = this.props.authReducer.accessToken;
        }
        this.props.requestProductDetails(this.props.productServiceReducer.product.data.id, token, receivingCountryAlpha, sendingCountryAlpha);
    }
  }

  reset() {
    this.setState({
      recipients : [],
      next : null,
      prev : null,
      first : null,
      last: null,
      loading : false,
      loadingTip : "",
    });
    if (this.state.currentLink) {
        this.requestRecipients(this.state.currentLink)
    }
  }

  onDelete = (recipient) => {
    Log.d(TAG, "onDelete() -- recipient=" + JSON.stringify(recipient));
    const { deleteRecipient } = this.props;

    confirm({
      title: 'Do you want to remove ' + recipient.name + ' as a recipient?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk : () => {
        // if (cookies.get('access')) {
        if (!!this.props.authReducer.accessToken) {

          // deleteRecipient(recipient.id, cookies.get('access'))
          deleteRecipient(recipient.id, this.props.authReducer.accessToken)
        }
      },
      onCancel() {},
    });
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </div>
    )
  }

  renderContent() {
    if (!this.props.userReducer.error) {
     return (this.renderTable())
    }
    return (this.renderError())
  }



  renderError() {
    Log.d(TAG, "renderError() -- error=" + this.props.userReducer.error);
    return (
      <Content className="recipient-content-container">
        <ErrorLayout {...this.props}/>
      </Content>
    )
  }

  renderTable() {
    return (
      <Content className="recipient-content-container">
        <div className="rl-top" style={{ paddingLeft: '10px', paddingRight: '10px'}}>
          <h2 className="rl-select-recipient">Select your recipient</h2>
          <div className="rl-p-search-add-right">
            <Search
              placeholder="Search recipient and press 'Enter'"
              onSearch={this.onSearch}
              className="search"
            />
            <Button className="add-recipient" icon="plus" onClick={this.onAddRecipient} disabled={this.state.disableAddButton}> Add Recipient</Button>
          </div>

        </div>
        <Divider />
        <div className="trl-point-table" style={{ width: '100%' }}>
          <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
            <Table
              rowClassName={(record, index) => index%2 === 0 ? '' : 'table-even-row' }
              size = "middle"
              pagination = {{hideOnSinglePage : true}}
              className="trans-body"
              onRow={(recipient) => {
                return {
                  onClick: () => {
                    Log.d(TAG, "onClickRow() -- recipient=" + JSON.stringify(recipient))
                    this.loadUpdateBeneficiary(recipient)
                  }
                };
              }}

              bordered
              dataSource={this.props.recipientReducer.recipients}
              columns={this.state.columns}/>
          </Spin>
          <div className="pagination-box">
            <div className = "pagination-box-left">
              {this.showPrevButton()}
                {this.showFirstButton()}
            </div>
            <div className = "pagination-box-middle">
              {this.renderCurrentLink()}
            </div>
            <div className = "pagination-box-right">
                {this.showLastButton()}
                {this.showNextButton()}
            </div>
          </div>
        </div>

      </Content>
    )
  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (<span>Page {this.state.currentLink.slice(this.state.currentLink.lastIndexOf("=") + 1, this.state.currentLink.length)} of {this.showLastPage()} pages</span>)
    }
    return (<div/>)
  }

  hasAcceptedDocuments() {
    this.enableAddButton()
    if (Object.keys(this.state.sendingCountry).length > 0) {
      Log.d(TAG, "hasAcceptedDocuments() -- has sending country")
      if (this.state.sendingCountry.id === this.props.userReducer.user.data.relationships.country.data.id) {
        Log.d(TAG, "hasAcceptedDocuments() -- reset equal")
        this.reset();
      } else {
        Log.d(TAG, "hasAcceptedDocuments() -- load home")
        this.props.history.push({
          pathname: "/",
        });
      }
    } else {
      Log.d(TAG, "hasAcceptedDocuments() -- reset ");
      this.reset();
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      if (this.props.userReducer.error.includes("password") && this.props.userReducer.error.includes("expired")) {
        message.error("Please change your password first.", 5);
        this.props.history.push({
          pathname: "/update-profile"
        })
      }
    }

    if (prevProps.recipientReducer.recipients !== this.props.recipientReducer.recipients) {
      Log.d(TAG, "componentDidUpdate() -- recipients=" + JSON.stringify(this.props.recipientReducer.recipients))
      this.setState({
        loading : false,
        loadingTip : "",
        currentLink : this.props.recipientReducer.url
      })
    }

    if (Object.keys(this.props.userReducer.user).length > 0 && this.props.userReducer.user !== prevProps.userReducer.user) {
      Log.d(TAG, "componentDidUpdate() -- set user");
      Log.d(TAG, "componentDidUpdate() -- source_of_registration=" + this.props.userReducer.user.data.attributes.source_of_registration);
      if (this.props.userReducer.user.data.attributes.password_reset_needed || this.props.userReducer.user.data.attributes.password_expired) {
        message.error("Please change your password first.", 5);
        this.props.history.push({
          pathname: "/update-profile"
        })
      } else {
        if (this.props.userReducer.user.data.attributes.phone_verified) {

          if (this.props.userReducer.user.data.attributes.user_sub_status != "PA") {
            if (Helper.acceptedIDDocumentsCount(this.props.userReducer.user) > 0) {
              if (this.props.userReducer.user.data.attributes.source_of_registration === "A") { // not important to check selfies of users registered by agents
                this.hasAcceptedDocuments();
              } else {
                if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
                  this.hasAcceptedDocuments();
                } else if ((Helper.pendingSelfiesCount(this.props.userReducer.user) <= 0)) {
                  message.error("Please complete the registration process to proceed.", 5);
                  this.props.history.push({
                    pathname: "/update-profile",
                    state: {
                      defaultActiveKey : "4",
                    }
                  })
                } else {
                  message.error("Account approval is currently in progress. For assistance, please contact our customer support on WhatsApp: +27611054898.", 5);
                }
              }
            } else if (Helper.pendingIDDocumentsCount(this.props.userReducer.user) > 0) {
              message.error("Account approval is currently in progress. For assistance, please contact our customer support on WhatsApp: +27611054898.", 5);
            } else {
              message.error("Please complete the registration process to proceed.", 5);
              this.props.history.push({
                pathname: "/update-profile",
                state: {
                  defaultActiveKey : "3",
                }
              })
            }
          } else {
            this.enableAddButton();
          }
        } else {
          message.error("Please verify your phone first.", 5);
          this.props.history.push({
            pathname: "/update-profile",
            state: {
              defaultActiveKey : "7",
            }
          })
        }
      }
    }

    if (prevProps.userReducer.reload !== this.props.userReducer.reload) {
      if (this.props.userReducer.reload) {
        window.location.reload();
      }
    }

    if (Object.keys(this.props.userReducer.limit).length > 0 && this.props.userReducer.limit !== prevProps.userReducer.limit) {
      Log.d(TAG, "componentDidUpdate() -- limit=" + JSON.stringify(this.props.userReducer.limit));
      if (this.props.userReducer.requestLimitSource) {
        Log.d(TAG, "componentDidUpdate() -- requestLimitSource=" + this.props.userReducer.requestLimitSource);
        Log.d(TAG, "componentDidUpdate() -- calculation=" + JSON.stringify(this.state.calculation));
        Log.d(TAG, "componentDidUpdate() -- sendingCurrency=" + JSON.stringify(this.state.sendingCurrency));
        this.checkUserLimit(this.props.userReducer.limit, Number(this.state.calculation.data.attributes.sending_amount), this.state.sendingCurrency.attributes.letter_code, (error) => {
          if (error) {
            message.error("" + error, 5);
          } else {
            if (this.props.userReducer.requestLimitSource === "rl-add") {
              this.showAdd()
            } else if (this.props.userReducer.requestLimitSource === "rl-update") {
              this.showUpdate();
            }
          }
        });
      }
    }

    if (this.props.productServiceReducer.product) {
      if ((prevProps.productServiceReducer.product !== this.props.productServiceReducer.product) && Object.keys(this.props.productServiceReducer.product).length > 0) {
        var included = this.props.productServiceReducer.product.included;
        var attributes = this.props.productServiceReducer.product.data.attributes;

        var paymentMethods = [];

        for (var i = 0; i < included.length; i++) {
          var includedObj = included[i];
          if (includedObj.type === "payment_methods") {
            paymentMethods.push(includedObj);
          }
        }

        this.setState({
          beneficiarySettings: attributes.beneficiary_settings,
          transferReasons: attributes.transfer_reasons,
          defaultTransferReason: attributes.default_transfer_reason,
          acceptedBeneficiaryTypes: attributes.accepted_beneficiary_types,
          acceptedRecipientTypes: attributes.accepted_recipient_types,
          paymentMethods: paymentMethods
        })
      }
    }

    if (Object.keys(this.props.recipientReducer.recipient).length > 0 && (prevProps.recipientReducer.recipient !== this.props.recipientReducer.recipient)) {
      this.props.history.push({
        pathname: "/manage-breadwinner-policy/",
        state: {
          recipientId : this.state.recipient.id,
          recipient: this.props.recipientReducer.recipient.data,
          bwBeneficiary: {}
        }
      });
    }

  }

  enableAddButton() {
    this.setState({
      disableAddButton : false
    })
  }

  requestBeneficiaryDetails(recipientID) {
    Log.d(TAG, "requestBeneficiaryDetails() -- called ..");
    // this.props.requestRecipientByID(recipientID, cookies.get('access'));
    this.props.requestRecipientByID(recipientID, this.props.authReducer.accessToken);
  }

  showUpdate(){
    Log.d(TAG, "showUpdate() -- bwPolicy=" + this.state.bwPolicy);
    if (Object.keys(this.state.recipient).length > 0) {
      if (this.state.bwPolicy) {
        this.requestBeneficiaryDetails(this.state.recipient.id);
        /*this.props.history.push({
          pathname: "/manage-breadwinner-policy/",
          state: {
            recipientId : this.state.recipient.id,
            bwBeneficiary: {}
          }
        });*/
      } else {
        this.props.history.push({
          pathname: "/manage-recipient/" + this.state.recipient.id,
          state: {
            sendingCountry : this.state.sendingCountry,
            receivingCountry: this.state.receivingCountry,
            product: this.state.product,
            calculation: this.state.calculation,
            beneficiarySettings: this.state.beneficiarySettings,
            transferReasons: this.state.transferReasons,
            defaultTransferReason: this.state.defaultTransferReason,
            acceptedBeneficiaryTypes: this.state.acceptedBeneficiaryTypes,
            acceptedRecipientTypes: this.state.acceptedRecipientTypes,
            paymentMethods : this.state.paymentMethods,
            receivingCurrency: this.state.receivingCurrency,
            sendingCurrency : this.state.sendingCurrency
          }
        });
      }
    }
  }

  showAdd() {
    if (this.state.bwPolicy) {
      this.props.history.push({
        pathname: "/manage-breadwinner-policy/",
        state: {
          recipientId : null,
          bwBeneficiary: {},
          recipient: {}
        }
      });
    } else {
      this.props.history.push({
        pathname: "/manage-recipient",
        state: {
          sendingCountry : this.state.sendingCountry,
          receivingCountry: this.state.receivingCountry,
          product: this.state.product,
          calculation: this.state.calculation,
          beneficiarySettings: this.state.beneficiarySettings,
          transferReasons: this.state.transferReasons,
          defaultTransferReason: this.state.defaultTransferReason,
          acceptedBeneficiaryTypes: this.state.acceptedBeneficiaryTypes,
          acceptedRecipientTypes: this.state.acceptedRecipientTypes,
          paymentMethods : this.state.paymentMethods,
          receivingCurrency: this.state.receivingCurrency,
          sendingCurrency : this.state.sendingCurrency
        }
      });
    }
  }

  checkUserLimit(limit,amount, sendingCurrencyLetterCode, cb) {
    var cpd = Number(limit.data.attributes.cumulative_per_day);
    var cpm = Number(limit.data.attributes.cumulative_per_month);
    var cpy = Number(limit.data.attributes.cumulative_per_year);

    var tpd = Number(limit.data.attributes.total_per_day);
    var tpm = Number(limit.data.attributes.total_per_month);
    var tpy = Number(limit.data.attributes.total_per_year);

    var futureCpd = cpd + amount;
    var futureCpm = cpm + amount;
    var futureCpy = cpy + amount;

    Log.d(TAG, "checkUserLimit() -- amount=" + amount);
    Log.d(TAG, "checkUserLimit() -- CUMULATIVE cpd=" + cpd + " cpm=" + cpm + " cpy=" + cpy);
    Log.d(TAG, "checkUserLimit() -- TOTAL tpd=" + tpd + " tpm=" + tpm + " tpy=" + tpy);
    Log.d(TAG, "checkUserLimit() -- FUTURE fcpd=" + futureCpd + " fcpm=" + futureCpm + " fcpy=" + futureCpy);

    // when Number is given a null value is returns 0 (Number(null) === 0)
    if(tpd !== 0 && tpm !== 0 && tpy !== 0) {
      if (futureCpd > tpd) {
        Log.d(TAG, "checkUserLimit() -- show day error");
        cb("You cannot proceed as the amount " + amount+ " " + sendingCurrencyLetterCode
          + " will exceed your day limit of " + tpd + " " + sendingCurrencyLetterCode + ". Your already sent "
          + cpd + " " + sendingCurrencyLetterCode+ " amount for this day.");
      } else if (futureCpm > tpm) {
        Log.d(TAG, "checkUserLimit() -- show month error");
        cb("You cannot proceed as the amount " + amount+ " " + sendingCurrencyLetterCode
           + " will exceed your month limit of " + tpm + " " + sendingCurrencyLetterCode + ". Your already sent "
           + cpm + " " + sendingCurrencyLetterCode+ " amount for this month.");
      } else if (futureCpy > tpy) {
        Log.d(TAG, "checkUserLimit() -- show year error");
        cb("You cannot proceed as the amount " + amount+ " " + sendingCurrencyLetterCode
           + " will exceed your year limit of " + tpy + " " + sendingCurrencyLetterCode + ". Your already sent "
           + cpy + " " + sendingCurrencyLetterCode+ " amount for this year.");
      } else {
        Log.d(TAG, "checkUserLimit() -- still safe limit");
        cb(null)
      }
    } else {
      cb(null)
    }
  }

  loadUpdateBeneficiary = (recipient) => {
    Log.d(TAG, "onClickRow() -- recipient=" + JSON.stringify(recipient))
    //check if the recipient is acceptable for the chosen product
    if (this.state.acceptedRecipientTypes.length > 0) {
      if (this.state.acceptedRecipientTypes.filter(e => e === (recipient.recipient_type === "Person" ? "P" : "E")).length > 0) {
        this.allowRecipient(recipient)
      } else {
        message.error("You cannot send money with the chosen recipient due to his recipient type.")
      }
    } else {
      this.allowRecipient(recipient)
    }
  }

  allowRecipient(recipient) {
    this.setState({
      recipient
    })

    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      // this.props.requestLimit(this.props.userReducer.user.data.id, this.props.cookies.cookies.access, "rl-update");
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "rl-update");
    } else {
      this.showUpdate();
    }
  }

  onAddRecipient = () => {
    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      // this.props.requestLimit(this.props.userReducer.user.data.id, this.props.cookies.cookies.access, "rl-add");
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "rl-add");
    } else {
      this.showAdd()
    }
  }

  showLastPage() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <span>{this.props.recipientReducer.last.slice(this.props.recipientReducer.last.lastIndexOf("=") + 1, this.props.recipientReducer.last.length)}</span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  showFirstButton() {
    if (this.props.recipientReducer.first !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.last)}>Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.recipientReducer.prev !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.prev)}>Prev</Button>
      )
    }else {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.recipientReducer.next !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.next)}>Next&gt;&gt;</Button>
      )
    }
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    this.requestRecipients(Object.keys(this.state.receivingCountry).length > 0 ? config.api_url + "beneficiaries/?filter[beneficiary_type]=MT&filter[country]=" + this.state.receivingCountry.id + "&filter[q]=" + value + "&page=1" : config.api_url + "beneficiaries/?filter[q]=" + value + "&page=1")
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestRecipients,
    fetchUserDetails,
    deleteRecipient,
    showRecipient,
    showReceivingCountry,
    showSendingCountry,
    showSendingCurrency,
    showReceivingCurrency,
    showCalculation,
    showProductDetails,
    requestLimit,
    requestProductDetails,
    requestRecipientByID
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientList)
